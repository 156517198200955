






















































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import administration from '@/components/administration.vue'; // @ is an alias to /src
import administrationheader from '@/components/administrationheader.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';

@Component({
  components: {
    Header,
    Loader,
    administration,
    administrationheader
  },
})
export default class EmploymentBuiltin extends Vue {
    public fields = [{key: 'title', sortable: true },{key: 'actions', label: 'Actions'}];
  error_mesg = null;
  public items= [];
  private employee_type: any = { title: ""};
  loading = true;
  filterOn = [];
  filter = null;
  public currentPage = 1;
  get rows(){return this.items.length;}
  
  retrieve() {
    this.loading = true;
    BuiltinService.getemploymenttype().then((response) => {
        this.items = response.data;
      this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  handleSubmit(){
    this.create();
  }
  
  create() {
    this.loading = true;
         this.error_mesg = null;
    if(this.employee_type['id'] != null){
     BuiltinService.putemploymenttype(this.employee_type['id'], this.employee_type)
      .then((response) => {
        if(response){
          this.retrieve();
          this.employee_type = {title : ""}
          this.loading = false;
        }
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
    }else{
    BuiltinService.postemploymenttype(this.employee_type)
      .then((response) => {
        if(response){
          this.retrieve();
          this.employee_type = {title : ""}
          this.loading = false;
        }
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
    }
  }
  onFiltered(filteredItems:any) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    // this.rows = filteredItems.length
    // this.currentPage = 1
  }
     delete(id: any){
     BuiltinService.deleteemploymenttype(id)
      .then((response) => {
        if(response){
          this.retrieve();
          this.employee_type = {id: null, title : ""}
            this.loading = false;
        }
      })
      .catch((e) => {
        let eror = e.response.data;
        let r = eror[Object.keys(eror)[0]];
        this.error_mesg = r;
          this.loading = false;
      });
  }

  info(row: any){
    this.employee_type['id'] = row['id']; 
    this.employee_type['title'] = row['title'];
    this.employee_type['user'] = row['user'];
  }
  removeinfo(id: any){
    this.delete(id);
  }  
  mounted() {
  this.retrieve();
  }
}
